import React, { FC } from 'react'
import { Link } from 'gatsby'

import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { inszuAppURL } from '../config'

interface WeApproveMoreProps {}

const WeApproveMore: FC<WeApproveMoreProps> = () => {
  return (
    <main>
      <Header />
      <div className="container px-4 mx-auto">
        <div className="flex flex-col py-4 lg:flex-row-reverse lg:py-10">
          <section className="mb-8 lg:flex-1 lg:ml-8">
            <div className="embed-container">
              <iframe
                title="We approve more"
                src="https://player.vimeo.com/video/713109048"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </section>
          <article className="lg:flex-1">
            <div className="mb-4 text-sm tracking-widest uppercase">learn more</div>
            <h1 className="mb-6 text-2xl font-bold leading-relaxed lg:mb-8 lg:text-4xl text-slate-900">
              We approve
              <br />
              more
            </h1>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              If you are concerned you may be ineligible due to pre-existing health conditions we can help. Our motto at
              Inszu is, “life insurance for everyone.” That's precisely why we built proprietary technology to make it
              easy and effective for you to qualify, quote, and apply all through our web-site. Here is how it works:
            </p>
            <h2 className="mb-4 text-lg font-bold lg:text-2xl">Qualify</h2>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Input your health and medical info before completing the application to see if you are eligible to apply.
              If for any reason our system states you're ineligible, one of our team members can assist you with a
              detailed explanation and assist you with another offer.
            </p>
            <h2 className="mb-4 text-lg font-bold lg:text-2xl">Quote</h2>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Conveniently explore and adjust multiple coverage options to match your budget.
            </p>
            <h2 className="mb-4 text-lg font-bold lg:text-2xl">Apply</h2>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Once you have found a coverage amount you are happy with you can complete an application within minutes,
              or at your convenience 24/7 through your mobile phone, tablet or computer.
            </p>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Simply put, we are here to get more people approved for a life insurance policy that they are happy with
              and can depend on.
            </p>
            <Link
              to={inszuAppURL}
              className="px-6 py-4 text-white transition bg-orange-600 rounded-lg appearance-none motion-reduce:transition-none hover:bg-orange-700"
            >
              Get Started
            </Link>
          </article>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default WeApproveMore
